import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { skip, takeUntil } from "rxjs/operators";
import { combineLatest } from "rxjs";
import {
  AlertResponse,
  AlertSource,
  AlertStatus,
  PairedDevice,
  PresenceEventStatus,
  ResolutionType,
  RoomType,
  Suite,
} from "@walabot-mqtt-dashboard/api";
import { BaseComponent } from "../base-component";
import {
  AppUser,
  DATE_TIME_FORMAT,
  DeviceMonitoringService,
  EventBusService,
  getDeviceIcon,
  HistoryService,
  RoomsService,
  RoomTypeMapping,
  SuitesService,
  UserService,
} from "../ui.module";
import { DateTime } from "luxon";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ExportToCsv } from "export-to-csv";
import { SuiteRoomMapping } from "../suites.service";
import { DatePipe, KeyValue } from "@angular/common";
import { AlertQuery } from "../history.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { UnresolvedAlertsDialogComponent } from "./unresolved-alerts-dialog/unresolved-alerts-dialog.component";
import { moment } from "../models";

import * as momentTimezone from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
import firebase from "firebase/compat";
import { getUserNameAndEmail } from "../utils";
const moment: moment = momentTimezone as moment;

interface UIDashboardEvent {
  alertId: string;
  timestamp: number;
  deviceId: string;
  roomName: string;
  roomType: RoomType;
  roomTypeName: string;
  roomIcon: string;
  roomId: string;
  eventStr: string;
  iconClass: string;
  source: AlertSource | PresenceEventStatus;
  sourceName: string;
  apartment: string;
  suiteId: string;
  resolutionType?: ResolutionType;
  resolvedAt?: number;
  originalResolution?: string;
  resolutionReportedBy?: string;
  resolutionEditedBy?: string;
  resolution?: string;
  lastResolutionType?: ResolutionType;
  lastResolutionUpdatedAt?: number;
  status: AlertStatus;
}

enum TimeRange {
  HOURS_8,
  HOURS_24,
  WEEK,
  MONTH,
  SIX_MONTHS,
  CUSTOM,
}

interface DateTimeChange {
  input: Element;
  source: any;
  value: Array<Date>;
}

enum PopoverEditStatus {
  FIRST,
  NEXT,
}

export const HARDCODED_GO_LIVE_DATES: { [uid: string]: Date } = {
  "3p8mksfnNzYxJFbHuy3rVUWlm6P2": new Date(2024, 7, 13),
  U0tS8T4GSdf1KrZ81r2D2Ulwz8d2: new Date(2024, 7, 5),
  "5WUPxMYfKzLPfsLW7vIcrcXQpx43": new Date(2024, 6, 20),
  ZngKR1jchdUxueZyO6zptvFOYGK2: new Date(2024, 6, 31),
};

@Component({
  selector: "app-history",
  templateUrl: "./alerts-history.component.html",
  styleUrls: ["./alerts-history.component.css"],
})
export class AlertsHistoryComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  SelectAllSuites = "-1";
  SelectAllResolutions = "-1";
  UnresolvedResolution = "-2";
  SelectUnassignedDevices = "unassigned-devices-key";
  @ViewChild("dateTimeTrigger", { static: false }) dateTimeTrigger: ElementRef;
  @ViewChild("customTimeOption", { static: false })
  customTimeOption: ElementRef;
  @ViewChild("dateSelector", { static: false }) dateSelector: MatSelect;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginator", { static: false, read: ElementRef })
  paginatorEl: ElementRef<HTMLElement>;
  displayedColumns: string[] = [
    "apartment",
    "roomType",
    "roomName",
    "timestamp",
    "source",
    "resolution",
    "lastResolutionUpdatedAt",
  ];
  events: UIDashboardEvent[];
  eventsDataSource = new MatTableDataSource([]);
  selectedSuites: Array<string> = [];
  selectedResolutions: Array<ResolutionType | string> = [
    this.SelectAllResolutions,
    ...Object.values(ResolutionType).filter(
      (type) => type !== ResolutionType.LEARNING
    ),
    this.UnresolvedResolution,
  ];
  rooms: Map<string, { name: string; suiteId: string; devices: Set<string> }>;
  suiteMapping: { [suiteId: string]: Suite } = {};
  suiteRoomMapping: SuiteRoomMapping = {};
  unassignedDevices: Set<string> = new Set();
  selectedRange: TimeRange = TimeRange.SIX_MONTHS;
  timeRanges = [
    {
      name: $localize`:@@range-last-8-hours:Last 8 Hours`,
      value: TimeRange.HOURS_8,
    },
    {
      name: $localize`:@@yesterday:Yesterday`,
      value: TimeRange.HOURS_24,
    },
    {
      name: $localize`:@@range-last-full-week:Last full week`,
      value: TimeRange.WEEK,
    },
    {
      name: $localize`:@@range-last-full-month:Last full month`,
      value: TimeRange.MONTH,
    },
    {
      name: $localize`:@@range-last-full-6-months:6 months as of yesterday`,
      value: TimeRange.SIX_MONTHS,
    },
  ];
  customTimeRange = TimeRange.CUSTOM;
  selectedFrom: number = null;
  selectedTo: number = null;
  isLoading = false;
  historyResult: Array<AlertResponse>;
  historyQuery: AlertQuery;
  keys = [
    "Alert ID",
    "Apartment",
    "Room Type",
    "Room Name",
    "Device ID",
    "Time & Date",
    "Event Type",
    "Resolution",
    "Date & Time of Resolution",
  ];
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: this.keys,
    showTitle: false,
    useBom: true,
    removeNewLines: true,
    filename: "history-events",
  };
  dateTimeFormat = DATE_TIME_FORMAT;
  minValidDateTime: Date;
  maxValidDateTime = new Date();
  detailedFallHistory: boolean;
  ResolutionType = ResolutionType;
  resolutionMap: {
    [key in ResolutionType]: { name: string };
  } = {
    [ResolutionType.CONFIRMED_WITHOUT_INJURY]: {
      name: $localize`:@@confirmed-fall-without-injury:Confirmed fall without injury`,
    },
    [ResolutionType.CONFIRMED_WITH_INJURY]: {
      name: $localize`:@@confirmed-fall-with-injury:Confirmed fall with injury`,
    },
    [ResolutionType.UNCONFIRMED]: {
      name: $localize`:@@no-fall:No Fall`,
    },
    [ResolutionType.LEARNING]: {
      name: $localize`:@@test:Test`,
    },
  };
  filterUnresolvedAlerts = false;
  user: AppUser["user"];
  suitesNumber: number;
  isAdmin = localStorage.getItem("basePath") === "admin"; // only control by url, Customers need to use
  unresolvedAlertsCount = 0;
  zoneAbbr: string;

  private pageWasChangedByUser = false;
  private activePopover: HTMLElement;
  popoverEditStatus: PopoverEditStatus;
  private offset: number;

  constructor(
    public eventBus: EventBusService,
    private roomService: RoomsService,
    private historyService: HistoryService,
    private suitesService: SuitesService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private userService: UserService,
    private monitoringService: DeviceMonitoringService
  ) {
    super();
    const zone = moment.tz.guess();
    this.zoneAbbr = moment.tz(zone).format("z");
    console.log(this.selectedResolutions);
  }

  ngOnInit() {
    this.rooms = new Map();
    this.eventsDataSource.sort = this.sort;
    this.eventsDataSource.paginator = this.paginator;
    this.userService.currentUser
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((user) => {
        this.user = user;
      });
    this.suitesService.suiteList
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((suiteList) => {
        this.suitesNumber = suiteList?.length ?? 0;
      });
  }

  ngAfterViewInit() {
    combineLatest([
      this.suitesService.suiteList,
      this.suitesService.roomMapping,
      this.roomService.deviceMapping,
      this.roomService.roomList,
      this.monitoringService.devices,
    ])
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(
        ([
          suiteList,
          suiteRoomMapping,
          roomDeviceMapping,
          roomList,
          pairedDevices,
        ]) => {
          const unassignedSet: Set<string> = new Set();
          if (pairedDevices) {
            pairedDevices.forEach((device: PairedDevice) => {
              unassignedSet.add(device.id);
            });
          }

          if (suiteRoomMapping && roomDeviceMapping) {
            this.suiteRoomMapping = suiteRoomMapping;
            const rooms = Object.keys(roomDeviceMapping);
            rooms.forEach((deviceId) => {
              const roomId = roomDeviceMapping[deviceId].roomId;
              if (!this.rooms.has(roomId)) {
                this.rooms.set(roomId, {
                  name: null,
                  suiteId: null,
                  devices: new Set<string>(),
                });
              }
              this.rooms.get(roomId).devices.add(deviceId);
              unassignedSet.delete(deviceId);
            });
          }
          this.unassignedDevices = unassignedSet;

          if (roomList) {
            roomList.forEach((room) => {
              if (!this.rooms.has(room.id)) {
                this.rooms.set(room.id, {
                  name: null,
                  suiteId: null,
                  devices: new Set<string>(),
                });
              }
              const roomWithSuiteId = Object.values(suiteRoomMapping)
                .flat()
                .find((r) => r.id === room.id);
              this.rooms.get(room.id).name = room.name;
              if (roomWithSuiteId) {
                this.rooms.get(room.id).suiteId = roomWithSuiteId.suiteId;
              }
            });
          }
          if (suiteList && Object.keys(suiteRoomMapping).length) {
            suiteList.forEach((suite) => {
              this.suiteMapping[suite.id] = suite;
            });
            if (suiteList.length > 0) {
              const selectedSuites = this.selectedSuites
                ? this.selectedSuites.filter(
                    (val) =>
                      val !== this.SelectAllSuites &&
                      val !== this.SelectUnassignedDevices
                  )
                : null;
              if (
                !(selectedSuites && selectedSuites.length === suiteList.length)
              ) {
                this.selectedSuites = [
                  this.SelectAllSuites,
                  this.SelectUnassignedDevices,
                ].concat(suiteList.map((suite) => suite.id));
                this.onTimeRangeChange(this.selectedRange);
              }
            }
          }
        }
      );

    const subscribe = this.eventBus.unresolvedAlertsCount
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((unresolvedAlertsCount) => {
        if (unresolvedAlertsCount === undefined) return;
        this.unresolvedAlertsCount = unresolvedAlertsCount;
        if (this.isAdmin) {
          this.openDialog(unresolvedAlertsCount);
        }
        setTimeout(() => {
          subscribe.unsubscribe();
        });
      });

    this.eventBus.fallCount
      .pipe(skip(1), takeUntil(this.ngUnsubsrcibe))
      .subscribe(() => {
        void this.getEvents();
      });
  }

  onMultiSelectSelectionOpened(isOpened: boolean) {
    if (!isOpened) {
      void this.getEvents();
    }
  }

  async downloadCSV() {
    let historyData: Array<AlertResponse> = [];
    this.isLoading = true;
    if (this.filterUnresolvedAlerts) {
      this.csvOptions.filename = "history-events-unresolved";
    } else {
      this.csvOptions.filename = "history-events";
    }
    const selectedSuites = this.selectedSuites.filter(
      (val) => val !== this.SelectAllSuites
    );
    historyData = await this.loadAlerts(
      selectedSuites,
      Number.MAX_SAFE_INTEGER
    );
    const events: Array<UIDashboardEvent> = historyData.map((event) =>
      this.convertAlertToUIEvent(event)
    );
    if (this.sort.active && this.sort.direction) {
      events.sort((a, b) => {
        const key = <keyof UIDashboardEvent>(<unknown>this.sort.active);
        let first: UIDashboardEvent, second: UIDashboardEvent;

        let result = 0;
        if (this.sort.direction === "asc") {
          first = a;
          second = b;
        } else {
          first = b;
          second = a;
        }
        if (first[key] && second[key]) {
          result = first[key].toString().localeCompare(second[key]?.toString());
        } else if (first[key]) {
          result = 1;
        } else if (second[key]) {
          result = -1;
        }
        return result;
      });
    }

    const csvData = events.map((event: UIDashboardEvent) => {
      return {
        "Alert ID": event.alertId,
        Apartment: event.apartment,
        "Room Type": event.roomTypeName,
        "Room Name": event.roomName,
        "Device ID": event.deviceId,
        "Time & Date": this.datePipe.transform(
          event.timestamp,
          this.dateTimeFormat
        ),
        "Event Type": event.sourceName,
        Resolution: event.resolution || "",
        "Date & Time of Resolution":
          this.datePipe.transform(
            event.lastResolutionUpdatedAt,
            this.dateTimeFormat
          ) || "",
      };
    });

    const csvExporter = new ExportToCsv(this.csvOptions);
    /**
     * Fixme: workaround for https://github.com/Vayyar/VayyarHomeCloud/issues/2349
     * Wait for https://github.com/alexcaza/export-to-csv/issues/10 to be fixed
     */

    // eslint-disable-next-line
    const _getBody = csvExporter["_getBody"].bind(csvExporter);
    csvExporter["_getBody"] = function () {
      try {
        // eslint-disable-next-line
        _getBody();
      } catch (e) {
        console.warn(e);
      }
    };
    csvExporter.generateCsv(csvData);
  }

  onTimeRangeChange(change) {
    if (change !== TimeRange.CUSTOM) {
      if (change === TimeRange.HOURS_8) {
        this.selectedTo = Date.now();
      } else {
        this.selectedTo = DateTime.local()
          .minus({ day: 1 })
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .valueOf();
      }
      switch (change) {
        case TimeRange.HOURS_8:
          this.selectedFrom = DateTime.local().minus({ hours: 8 }).valueOf();
          break;
        case TimeRange.HOURS_24:
          this.selectedFrom = DateTime.local()
            .minus({ hours: 24 })
            .startOf("day")
            .valueOf();
          break;
        case TimeRange.WEEK:
          this.selectedFrom = DateTime.local()
            .minus({ weeks: 1 })
            .startOf("day")
            .valueOf();
          break;
        case TimeRange.MONTH:
          this.selectedFrom = DateTime.local()
            .minus({ months: 1 })
            .startOf("day")
            .valueOf();
          break;
        case TimeRange.SIX_MONTHS:
          this.selectedFrom = DateTime.local()
            .minus({ months: 6 })
            .startOf("day")
            .valueOf();
          break;
      }
      console.log(
        `onTimeRangeChange ${JSON.stringify(new Date(this.selectedFrom))} 
          ${JSON.stringify(new Date(this.selectedTo))}`
      );
      void this.getEvents();
    }
  }

  onSelectedCustomDate(event: DateTimeChange) {
    const [to, from] = event.value;
    this.selectedTo = from.getTime();
    this.selectedFrom = to ? to.getTime() : Date.now();
    console.log("onSelectedCustomDate ", this.selectedTo, this.selectedFrom);
    this.dateSelector.close();
    void this.getEvents();
  }

  openCustomTimeRange(event: PointerEvent) {
    event.stopImmediatePropagation();
    this.selectedRange = TimeRange.CUSTOM;
    const location = (
      this.customTimeOption.nativeElement as Element
    ).getBoundingClientRect();
    let rightValue: string;
    if (document.body.dir === "rtl") {
      // Value from owl-date-time component
      const dateTimePickerPopupWidth = "18.5em";
      rightValue = `calc(${location.left}px - ${dateTimePickerPopupWidth})`;
    } else {
      rightValue = String(location.right) + "px";
    }
    document.documentElement.style.setProperty(
      "--date-picker-left",
      rightValue
    );
    document.documentElement.style.setProperty(
      "--date-picker-top",
      String(location.y) + "px"
    );
    const twelveMonthAgo = new Date();
    twelveMonthAgo.setMonth(twelveMonthAgo.getMonth() - 12);
    let minValidDateTime = twelveMonthAgo;
    if (this.user.uid in HARDCODED_GO_LIVE_DATES) {
      minValidDateTime = new Date(
        Math.max(+HARDCODED_GO_LIVE_DATES[this.user.uid], +twelveMonthAgo)
      );
    }
    this.minValidDateTime = minValidDateTime;
    this.maxValidDateTime = new Date();
    (this.dateTimeTrigger.nativeElement as HTMLElement).click();
  }

  onFilterUnresolvedAlerts() {
    this.filterUnresolvedAlerts = !this.filterUnresolvedAlerts;
    void this.getEvents();
  }

  getUserNameAndEmail(user: firebase.User) {
    return getUserNameAndEmail(user);
  }

  /*
  Show only fall events (presence was removed)
  **/
  private async getEvents() {
    const selectedSuites = this.selectedSuites.filter(
      (val) => val !== this.SelectAllSuites
    );
    this.historyResult = null;
    this.events = [];
    this.eventsDataSource.data = [];
    if (this.selectedSuites.length === 0) {
      return;
    }
    this.isLoading = true;
    const historyData = await this.loadAlerts(
      selectedSuites,
      this.paginator.pageSize * 2
    );
    this.historyResult = historyData;
    this.events = historyData.map((event) => this.convertAlertToUIEvent(event));
    this.eventsDataSource.data = this.events;
    if (!this.filterUnresolvedAlerts) {
      void this.updateUnresolvedAlertsCount(selectedSuites);
    }
  }

  private updateUnresolvedAlertsCount(selectedSuites: Array<string>) {
    if (selectedSuites.length === 0) {
      return;
    }
    let fromCreatedAt = DateTime.local().minus({ months: 6 }).toJSDate();
    const toCreatedAt = new Date(this.selectedTo);
    if (this.user.uid in HARDCODED_GO_LIVE_DATES) {
      fromCreatedAt = new Date(
        Math.max(+HARDCODED_GO_LIVE_DATES[this.user.uid], +fromCreatedAt)
      );
    }
    fromCreatedAt.setSeconds(0, 0);
    toCreatedAt.setSeconds(59, 999);
    let unassignedDeviceIds = [];
    if (selectedSuites.includes(this.SelectUnassignedDevices)) {
      unassignedDeviceIds = Array.from(this.unassignedDevices);
    }
    const historyQuery: AlertQuery = {
      unassignedDeviceIds,
      suiteIds: selectedSuites.filter(
        (suiteId) => suiteId !== this.SelectUnassignedDevices
      ),
      fromCreatedAt: fromCreatedAt.toISOString(),
      toCreatedAt: toCreatedAt.toISOString(),
      statuses: [AlertStatus.Active, AlertStatus.Taken],
      limit: Number.MAX_SAFE_INTEGER,
    };
    return this.historyService.getAlerts(historyQuery).then((events) => {
      const localTime = DateTime.local();
      this.unresolvedAlertsCount = events.filter((alert) => {
        const diff = localTime
          .diff(DateTime.fromISO(alert.createdAt))
          .toMillis();

        return diff > 24 * 60 * 60 * 1000;
      }).length;
    });
  }

  private loadAlerts(
    selectedSuites: Array<string>,
    limit: number,
    offset = 0,
    ret: Array<AlertResponse> = [],
    final = true
  ) {
    const isDownloadAllEvents = limit === Number.MAX_SAFE_INTEGER;
    if (selectedSuites.length === 0) {
      console.warn("loadAlerts the selectedSuites is empty");
      this.isLoading = false;
      return Promise.resolve(<Array<AlertResponse>>[]);
    }
    if (offset === 0 && !isDownloadAllEvents) {
      this.offset = 0;
    }
    let fromCreatedAt = this.filterUnresolvedAlerts
      ? DateTime.local().minus({ months: 6 }).toJSDate()
      : new Date(this.selectedFrom);
    const toCreatedAt = new Date(this.selectedTo);
    if (this.user.uid in HARDCODED_GO_LIVE_DATES) {
      fromCreatedAt = new Date(
        Math.max(+HARDCODED_GO_LIVE_DATES[this.user.uid], +fromCreatedAt)
      );
    }
    fromCreatedAt.setSeconds(0, 0);
    toCreatedAt.setSeconds(59, 999);
    let unassignedDeviceIds = [];
    if (selectedSuites.includes(this.SelectUnassignedDevices)) {
      unassignedDeviceIds = Array.from(this.unassignedDevices);
    }
    const historyQuery: AlertQuery = {
      unassignedDeviceIds,
      suiteIds: selectedSuites.filter(
        (suiteId) => suiteId !== this.SelectUnassignedDevices
      ),
      fromCreatedAt: fromCreatedAt.toISOString(),
      toCreatedAt: toCreatedAt.toISOString(),
      limit,
      offset: isDownloadAllEvents ? offset : this.offset,
    };
    if (this.filterUnresolvedAlerts) {
      historyQuery.statuses = [AlertStatus.Active, AlertStatus.Taken];
    } else {
      historyQuery.resolutionTypes = <Array<ResolutionType>>(
        this.selectedResolutions.filter(
          (val) =>
            val !== this.SelectAllResolutions &&
            val !== this.UnresolvedResolution
        )
      );
      if (this.selectedResolutions.includes(this.UnresolvedResolution)) {
        historyQuery.statuses = [AlertStatus.Active, AlertStatus.Taken];
      }
    }
    const localTime = DateTime.local();
    return this.historyService
      .getAlerts(historyQuery)
      .then((events) => {
        return {
          responseTotal: events.length,
          events: events.filter((alert) => {
            const diff = localTime
              .diff(DateTime.fromISO(alert.createdAt))
              .toMillis();

            return (
              diff > 24 * 60 * 60 * 1000 ||
              alert.status === AlertStatus.Resolved
            );
          }),
        };
      })
      .then(async (res) => {
        let nextPageEvents: Array<AlertResponse> = [];
        if (!isDownloadAllEvents) {
          this.offset += limit;
        }
        /**
         * After filtered out unresolved alerts in last 24 hours on a previous step
         * request new portion of alerts until reach requested alerts number (limit)
         */
        if (res.responseTotal === limit && res.events.length < limit) {
          nextPageEvents = await this.loadAlerts(
            selectedSuites,
            limit - res.events.length,
            this.offset,
            ret,
            false
          );
        }
        return ret.concat(res.events, nextPageEvents);
      })
      .catch((err) => {
        console.warn(
          AlertsHistoryComponent.name,
          `Failed to load event ${JSON.stringify(err)}`
        );
        return Promise.resolve(<Array<AlertResponse>>[]);
      })
      .finally(() => {
        if (final) {
          this.isLoading = false;
        }
      });
  }

  onTableContentChanged() {
    if (this.pageWasChangedByUser) {
      this.paginatorEl.nativeElement.scrollIntoView();
      this.pageWasChangedByUser = false;
    }
  }

  onPageChanged(event: PageEvent) {
    this.pageWasChangedByUser = true;
    const isMovingForward = event.previousPageIndex < event.pageIndex;
    if (
      isMovingForward &&
      event.length - (event.pageIndex + 1) * event.pageSize < event.pageSize &&
      this.historyResult.length
    ) {
      void this.loadMore();
    }
  }

  getRoomTypeName(type: RoomType) {
    return RoomTypeMapping.get(parseInt(String(type), 10)).name;
  }

  private async loadMore() {
    const selectedSuites = this.selectedSuites.filter(
      (val) => val !== this.SelectAllSuites
    );
    this.isLoading = true;
    const historyData = await this.loadAlerts(
      selectedSuites,
      this.paginator.pageSize,
      this.paginator.pageIndex * this.paginator.pageSize +
        this.paginator.pageSize
    );
    this.historyResult = historyData;
    this.events = this.events.concat(
      historyData.map((event) => this.convertAlertToUIEvent(event))
    );
    this.eventsDataSource.data = this.events;
  }

  private convertAlertToUIEvent(event: AlertResponse): UIDashboardEvent {
    const resolvedAt = event.resolvedAt
      ? new Date(event.resolvedAt).getTime()
      : null;
    const resolutionUpdatedAt = event.resolutionUpdatedAt
      ? new Date(event.resolutionUpdatedAt).getTime()
      : null;
    const lastResolutionType =
      event.updatedResolutionType ?? event.resolutionType;
    return {
      alertId: event.alertId,
      deviceId: event.deviceId,
      timestamp: new Date(event.createdAt).getTime(),
      roomName: event.roomName,
      roomType: event.roomType,
      roomTypeName:
        event.roomType > -1 ? this.getRoomTypeName(event.roomType) : "",
      roomIcon: event.roomType > -1 ? getDeviceIcon(event.roomType) : undefined,
      eventStr: "N/A",
      iconClass: "",
      source: event.source,
      sourceName:
        event.source === AlertSource.SensitiveFall
          ? "Target on the ground"
          : event.source,
      roomId: event.roomId,
      apartment:
        event.suiteName ??
        this.suiteMapping[this.rooms.get(event.roomId)?.suiteId]?.name ??
        "",
      suiteId: event.suiteId,
      resolutionType: event.resolutionType,
      originalResolution:
        this.resolutionMap[event.resolutionType]?.name ?? event.resolutionType,
      resolvedAt: resolvedAt,
      resolutionReportedBy: event.resolutionReportedBy,
      resolutionEditedBy: event.resolutionEditedBy,
      resolution:
        this.resolutionMap[lastResolutionType]?.name ?? lastResolutionType,
      lastResolutionType: lastResolutionType,
      lastResolutionUpdatedAt: resolutionUpdatedAt ?? resolvedAt,
      status: event.status,
    };
  }

  toggleSelectAllSuites(event: PointerEvent) {
    event.stopPropagation();
    const selectedSuites = this.selectedSuites.filter(
      (val) =>
        val !== this.SelectAllSuites && val !== this.SelectUnassignedDevices
    );
    if (selectedSuites.length !== Object.keys(this.suiteMapping).length) {
      this.selectedSuites = Object.keys(this.suiteMapping);
      this.selectedSuites = [
        this.SelectAllSuites,
        this.SelectUnassignedDevices,
      ].concat(this.selectedSuites);
    } else {
      this.selectedSuites = [];
    }
  }

  toggleSelectAllResolutions(event: PointerEvent) {
    event.stopPropagation();
    const selectedResolutions = this.selectedResolutions.filter(
      (val) => val !== this.SelectAllResolutions
    );
    if (selectedResolutions.length !== Object.keys(ResolutionType).length) {
      this.selectedResolutions = [this.SelectAllResolutions].concat(
        ...Object.values(ResolutionType).filter(
          (type) => type !== ResolutionType.LEARNING
        ),
        this.UnresolvedResolution
      );
    } else {
      this.selectedResolutions = [];
    }
  }

  selectedSuiteChanged(event: Array<string>) {
    // all lenght = all suites + the UnassignedDevices option
    let allLength = Object.keys(this.suiteMapping).length;
    allLength += this.unassignedDevices.size > 0 ? 1 : 0;

    if (event[0] === this.SelectAllSuites) {
      if (event.length - 1 !== allLength) {
        this.selectedSuites = event.filter(
          (val) => val !== this.SelectAllSuites
        );
      }
    } else {
      if (event.length === allLength) {
        this.selectedSuites = [this.SelectAllSuites].concat(
          this.selectedSuites
        );
      }
    }
  }

  selectedResolutionChanged(event: Array<string>) {
    const allLength = Object.keys(ResolutionType).length;

    if (event[0] === this.SelectAllResolutions) {
      if (event.length - 1 !== allLength) {
        this.selectedResolutions = event.filter(
          (val) => val !== this.SelectAllResolutions
        );
      }
    } else {
      if (event.length === allLength) {
        this.selectedResolutions = [this.SelectAllResolutions].concat(
          this.selectedResolutions
        );
      }
    }
  }

  getTotalNumberOfUnassignedDevicesText() {
    return $localize`:@@total-number-of-unassigned-devices:Unassigned Devices (${this.unassignedDevices.size}:totalNumber:)`;
  }

  refresh() {
    void this.getEvents();
  }

  valueAscOrder = (
    a: KeyValue<string, Suite>,
    b: KeyValue<string, Suite>
  ): number => {
    return a.value.name.localeCompare(b.value.name);
  };

  changeResolution(event: UIDashboardEvent, resolution: ResolutionType) {
    let editedBy: string;
    if (this.popoverEditStatus === PopoverEditStatus.FIRST) {
      editedBy = this.user.email;
    }
    const updateAlertResolution = {
      resolutionType: event.resolutionType ? event.resolutionType : resolution,
      updatedResolutionType: event.resolutionType ? resolution : undefined,
      resolutionEditedBy: editedBy,
    };
    this.historyService
      .updateAlertResolution(event.alertId, updateAlertResolution)
      .then(() => {
        event.originalResolution =
          this.resolutionMap[event.resolutionType]?.name ??
          event.resolutionType;
        event.resolutionType = updateAlertResolution.resolutionType;
        if (!event.resolutionType) event.resolvedAt = Date.now();
        event.resolution = this.resolutionMap[resolution]?.name ?? resolution;
        event.lastResolutionType = resolution;
        event.lastResolutionUpdatedAt = Date.now();
        event.status = AlertStatus.Resolved;
        event.resolutionEditedBy = editedBy;

        this.unresolvedAlertsCount = this.events.filter((alert) =>
          [AlertStatus.Active, AlertStatus.Taken].includes(alert.status)
        ).length;
        this.monitoringService.loadAlerts();
      })
      .catch((err) => {
        console.error(err);
        this.snackBar.open(
          $localize`:@@update-resolution-failed:Failed to update resolution`,
          null,
          {
            duration: 2000,
            panelClass: ["success-msg", "error"],
          }
        );
      });
  }

  openDialog(unresolvedAlertsCount: number) {
    if (unresolvedAlertsCount <= 0) return;
    this.dialog.open(UnresolvedAlertsDialogComponent, {
      data: {
        unresolvedAlertsCount,
        btnHandler: (confirmed: boolean) => {
          if (confirmed) {
            this.onFilterUnresolvedAlerts();
          }
        },
      },
    });
  }

  openPopover(popover: HTMLElement, activePopoverIsEdit = false) {
    this.closePopover(this.activePopover);
    console.log("activePopoverIsEdit: ", activePopoverIsEdit);
    this.popoverEditStatus = activePopoverIsEdit
      ? PopoverEditStatus.FIRST
      : undefined;
    popover.classList.toggle("open");
    this.activePopover = popover;
  }

  closePopover(popover: HTMLElement) {
    popover?.classList.toggle("open");
    this.activePopover = null;
    this.popoverEditStatus = undefined;
  }
}
