import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { DashboardConfig } from "@walabot-mqtt-dashboard/api";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "../../base-component";
import {
  ConfigService,
  Language,
  MeasurementUnits,
} from "../../config.service";
import { Environment } from "../../models";
import TimezoneEnum from "timezone-enum";

interface DashboardSettingsForm {
  measurementUnits: FormControl<MeasurementUnits>;
  alertsFrom: FormControl<string>;
  alertsTo: FormControl<string>;
  timeZone: FormControl<string>;
  detailedFallHistory: FormControl<boolean>;
  language: FormControl<string>;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";

  function supportedValuesOf(input: Key): string[];
  function DateTimeFormat(): {
    resolvedOptions(): {
      timeZone: string;
    };
  };
}

const getOffset = (timeZone = "UTC", date = new Date()) => {
  /**
   * Since 131.0.6778.70 Chrome throw "RangeError: Invalid time zone specified: Factory"
   * As it's an alias to GMT - use it instead.
   */
  if (timeZone === "Factory") {
    timeZone = "GMT";
  }
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

const formatHour = (minutes: number) => {
  const hour = Math.floor(Math.abs(minutes) / 60);
  const minute = Math.abs(minutes) - hour * 60;
  return `${hour < 10 ? "0" : ""}${hour}:${minute < 10 ? "0" : ""}${minute}`;
};

interface TimeZone {
  value: string;
  name: string;
}

// Value of time_zone, Description
const GMTTimeMap = {
  "Etc/GMT+12": "GMT-12:00",
  "Etc/GMT+11": "GMT-11:00",
  "Etc/GMT+10": "GMT-10:00",
  "Etc/GMT+9": "GMT-09:00",
  "Etc/GMT+8": "GMT-08:00",
  "Etc/GMT+7": "GMT-07:00",
  "Etc/GMT+6": "GMT-06:00",
  "Etc/GMT+5": "GMT-05:00",
  "Etc/GMT+4": "GMT-04:00",
  "Etc/GMT+3": "GMT-03:00",
  "Etc/GMT+2": "GMT-02:00",
  "Etc/GMT+1": "GMT-01:00",
  "Etc/GMT0": "GMT+00:00",
  "Etc/GMT-1": "GMT+01:00",
  "Etc/GMT-2": "GMT+02:00",
  "Etc/GMT-3": "GMT+03:00",
  "Etc/GMT-4": "GMT+04:00",
  "Etc/GMT-5": "GMT+05:00",
  "Etc/GMT-6": "GMT+06:00",
  "Etc/GMT-7": "GMT+07:00",
  "Etc/GMT-8": "GMT+08:00",
  "Etc/GMT-9": "GMT+09:00",
  "Etc/GMT-10": "GMT+10:00",
  "Etc/GMT-11": "GMT+11:00",
  "Etc/GMT-12": "GMT+12:00",
  "Etc/GMT-13": "GMT+13:00",
  "Etc/GMT-14": "GMT+14:00",
};

@Component({
  selector: "app-dashboard-settings",
  templateUrl: "./dashboard-settings.component.html",
  styleUrls: ["./dashboard-settings.component.css"],
})
export class DashboardSettingsComponent
  extends BaseComponent
  implements OnInit
{
  inProgress: boolean;
  config: DashboardConfig;
  units: number[];
  dashboardSettingsForm: FormGroup<DashboardSettingsForm> = this.fb.group({
    measurementUnits: this.fb.control(0),
    alertsFrom: this.fb.control(
      "",
      null,
      async (control: AbstractControl<string>) => {
        await Promise.resolve();
        let error: null | { [key: string]: boolean } = null;
        const alertsFrom = control.value,
          alertsTo = this.dashboardSettingsForm?.get("alertsTo").value;
        if (this.lastAlertTimeInputChanged === "alertsFrom") {
          if (!alertsFrom && alertsTo) {
            error = {
              alertsFromRequired: true,
            };
          }
        }
        return error;
      }
    ),
    alertsTo: this.fb.control(
      "",
      null,
      async (control: AbstractControl<string>) => {
        await Promise.resolve();
        let error: null | { [key: string]: boolean } = null;
        const alertsFrom = this.dashboardSettingsForm?.get("alertsFrom").value,
          alertsTo = control.value;
        if (this.lastAlertTimeInputChanged === "alertsTo") {
          if (alertsFrom && !alertsTo) {
            error = {
              alertsToRequired: true,
            };
          }
        }
        return error;
      }
    ),
    timeZone: this.fb.control(Intl.DateTimeFormat().resolvedOptions().timeZone),
    detailedFallHistory: this.fb.control(
      (JSON.parse(localStorage.getItem("detailedFallHistory")) as boolean) ??
        false
    ),
    language: this.fb.control(""),
  });

  alertTooltipText = $localize`:@@alert-time-bedexit-tooltip:Set alert times to receive dashboard alerts when the resident has left the bed.`;
  lastAlertTimeInputChanged;
  timeZones: TimeZone[];
  gmtTimeOffsetMap: Map<number, string> = new Map();
  additionalTimezoneMap: Map<string, string> = new Map();

  constructor(
    private configService: ConfigService,
    private fb: FormBuilder,
    @Inject("environment") public environment: { [key: string]: Environment }
  ) {
    super();
    this.units = Object.values(MeasurementUnits)
      .filter((val) => typeof val === "number")
      .map((val) => val as number);

    const gmtTimeList = Object.keys(GMTTimeMap).map((timeZone) => {
      return { value: timeZone, name: GMTTimeMap[timeZone] as string };
    });
    this.gmtTimeOffsetMap = new Map();
    gmtTimeList.map((item) => {
      const offset = getOffset(item.value);
      this.gmtTimeOffsetMap.set(offset, item.value);
    });

    // In order to be compatible with old backend timezone data and new special timezones
    const additionalTimezone: { value: string; name: string }[] = [];
    Object.values(TimezoneEnum).map((timeZone) => {
      const offset = getOffset(timeZone);
      if (!this.gmtTimeOffsetMap.get(offset)) {
        const timeStr = formatHour(offset);
        const timeZoneName = `${timeZone}(GMT${
          offset > 0 ? "+" : "-"
        }${timeStr})`;
        additionalTimezone.push({
          value: timeZone,
          name: timeZoneName,
        });
        this.additionalTimezoneMap.set(timeZone, timeZoneName);
      }
    });
    this.timeZones = gmtTimeList.concat(additionalTimezone);
  }

  ngOnInit(): void {
    this.configService
      .getDashboardConfig()
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((config) => {
        if (!config) {
          return;
        }
        this.config = config;
        this.setFormData(this.config);
      });
  }

  setFormData(config: DashboardConfig) {
    this.dashboardSettingsForm.markAsPristine();
    if (!config.presenceAlertsEnabledFrom && !config.presenceAlertsEnabledTo) {
      this.dashboardSettingsForm.get("alertsFrom").setValue(undefined);
      this.dashboardSettingsForm.get("alertsTo").setValue(undefined);
    } else {
      this.dashboardSettingsForm
        .get("alertsFrom")
        .setValue(config.presenceAlertsEnabledFrom);
      this.dashboardSettingsForm
        .get("alertsTo")
        .setValue(config.presenceAlertsEnabledTo);
    }
    this.dashboardSettingsForm
      .get("measurementUnits")
      .setValue(
        config.measurementUnits == null
          ? MeasurementUnits.Metric
          : config.measurementUnits
      );

    const timeZone =
      config.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.dashboardSettingsForm
      .get("timeZone")
      .setValue(this.getGMTTimeZone(timeZone));
    this.dashboardSettingsForm
      .get("language")
      .setValue(this.configService.getLanguage());
  }

  getGMTTimeZone(timeZone: string) {
    if (GMTTimeMap[timeZone]) return timeZone;

    const offset = getOffset(timeZone);
    const gmtTimeZone = this.gmtTimeOffsetMap.get(offset);
    if (gmtTimeZone) return gmtTimeZone;

    const additionalTimezone = this.additionalTimezoneMap.get(timeZone);
    if (additionalTimezone) return timeZone;

    const timeStr = formatHour(offset);
    const timeZoneName = `${timeZone}(GMT${offset > 0 ? "+" : "-"}${timeStr})`;
    this.timeZones.push({
      value: timeZone,
      name: timeZoneName,
    });
    this.additionalTimezoneMap.set(timeZone, timeZoneName);

    return timeZone;
  }

  save() {
    this.inProgress = true;
    const measurementUnits =
      this.dashboardSettingsForm.get("measurementUnits").value;
    if (this.units.includes(measurementUnits)) {
      this.config.measurementUnits = measurementUnits;
    }
    this.config.timeZone = this.dashboardSettingsForm.get("timeZone").value;
    this.config.timeZoneOffset = getOffset(this.config.timeZone);
    const alertsFrom = this.dashboardSettingsForm.get("alertsFrom").value;
    const alertsTo = this.dashboardSettingsForm.get("alertsTo").value;
    if (alertsFrom && alertsTo) {
      this.config.presenceAlertsEnabledFrom = alertsFrom;
      this.config.presenceAlertsEnabledTo = alertsTo;
    } else if (
      this.config.presenceAlertsEnabledFrom &&
      this.config.presenceAlertsEnabledTo
    ) {
      delete this.config.presenceAlertsEnabledFrom;
      delete this.config.presenceAlertsEnabledTo;
    }
    localStorage.setItem(
      "detailedFallHistory",
      JSON.stringify(
        this.dashboardSettingsForm.get("detailedFallHistory").value
      )
    );
    let reloadPageAfterSaving = false;
    if (
      this.configService.getLanguage() !==
      this.dashboardSettingsForm.get("language").value
    ) {
      this.configService.setLanguage(
        this.dashboardSettingsForm.get("language").value as Language
      );
      reloadPageAfterSaving = true;
    }
    this.configService
      .setDashboardConfig(this.config)
      .then(() => {
        this.dashboardSettingsForm.markAsPristine();
        this.inProgress = false;
        if (reloadPageAfterSaving) {
          window.location.reload();
        }
      })
      .catch((error) => {
        this.inProgress = false;
        console.error("Failed to set dashboard config: ", error);
      });
  }

  clearAlert() {
    const config = { ...this.config };
    delete config.presenceAlertsEnabledFrom;
    delete config.presenceAlertsEnabledTo;
    this.setFormData(config);
    this.dashboardSettingsForm.markAsDirty();
  }

  reset() {
    this.setFormData(this.config);
  }

  onLastAlertTimeInputChanged(input: string) {
    const inputToRunValidators =
      input === "alertsTo" ? "alertsFrom" : "alertsTo";
    this.lastAlertTimeInputChanged = input;
    this.dashboardSettingsForm
      .get(inputToRunValidators)
      .updateValueAndValidity();
  }

  langChange(lang: string) {
    this.dashboardSettingsForm.get("language").setValue(lang);
    this.dashboardSettingsForm.markAsDirty();
    console.log(this.dashboardSettingsForm.get("language").value);
  }
}
