/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportEmailConfig = {
    name: string;
    type: ReportEmailConfig.type;
    executeTime: string;
    recipientTo: Array<string>;
    recipientCC?: Array<string>;
    recipientBCC?: Array<string>;
    caregiverIds: Array<string>;
    timeZone?: string;
    pause?: boolean;
};

export namespace ReportEmailConfig {

    export enum type {
        DEVICE_OFFLINE_1_DAY = 'device_offline_1_day',
        DEVICE_OFFLINE_3_DAY = 'device_offline_3_day',
        FALL_EVENT_1_DAY = 'fall_event_1_day',
    }


}

