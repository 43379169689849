import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "../base-component";
import {
  ConfigService,
  DeviceMonitoringService,
  MeasurementUnits,
} from "../ui.module";
import { ActivatedRoute } from "@angular/router";
import { ngResizeObserverProviders } from "ng-resize-observer";
import { combineLatest } from "rxjs";
import {
  DeviceConfigGen2,
  SensorMounting,
  TrackerSubRegion,
} from "@walabot-mqtt-dashboard/api";
import { DeviceOfflineStatus, TrackerTarget, TrackerTarget2D } from "../models";
import { RoomSizeFromForm } from "../configuration/device-settings/device-settings-interface";

export const EXTRA_DEVICE_ID = new InjectionToken<object>("CONTAINER_DATA");
const SENSOR_COLOR = "rgba(90, 243, 164)";
const MONITORING_AREA_COLOR = "rgba(166, 218, 255, 0.38)";
const SUB_REGION_COLOR = "rgba(0, 110, 249, 0.4)";

@Component({
  selector: "app-targets",
  templateUrl: "./targets.component.html",
  styleUrls: ["./targets.component.css"],
  providers: [...ngResizeObserverProviders],
})
export class TargetsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output() closePanel = new EventEmitter<void>();

  //    UNKNOWN, STANDING, SITTING, LAYING, FALLING
  private imageUrl = [
    { url: "../../assets/targets/standing_man.svg", aspectRatio: 63 / 148 },
    { url: "../../assets/targets/standing_man.svg", aspectRatio: 63 / 148 },
    { url: "../../assets/targets/sitting_man.svg", aspectRatio: 72 / 118 },
    { url: "../../assets/targets/lie_down.svg", aspectRatio: 173 / 42 },
    { url: "../../assets/targets/fall_icon.svg", aspectRatio: 119 / 128 },
  ];
  private images = this.imageUrl.map((res) => {
    const image = new Image();
    image.src = res.url;
    return image;
  });
  _deviceId: string;
  loading = false;
  deviceName: string;
  roomName: string;
  config: DeviceConfigGen2;
  sensorColor = SENSOR_COLOR;
  monitoringAreaColor = MONITORING_AREA_COLOR;
  subRegionColor = SUB_REGION_COLOR;
  unit: MeasurementUnits;
  targets: Array<TrackerTarget2D> = [];

  private set device(deviceId: string) {
    this.loading = true;
    this._deviceId = deviceId;
    this.configService
      .getConfig(deviceId)
      .then((config) => {
        this.config = config;
        this.loading = false;
        const roomSizes: [number, number, number, number] = [
          "xMin",
          "xMax",
          "yMin",
          "yMax",
        ].map((value) => {
          return config.walabotConfig[value] * 100;
        }) as [number, number, number, number];
        this.configService
          .getDashboardConfig()
          .pipe(takeUntil(this.ngUnsubsrcibe))
          .subscribe((dashboardConfig) => {
            this.unit = dashboardConfig
              ? dashboardConfig.measurementUnits
              : MeasurementUnits.Metric;
          });
        combineLatest([
          this.deviceMonitoring.presenceObservable,
          this.deviceMonitoring.deviceStateMap,
        ])
          .pipe(takeUntil(this.ngUnsubsrcibe))
          .subscribe(([presenceMap, stateMap]) => {
            const presenceEvent = presenceMap[this._deviceId];
            const stateEvent = stateMap ? stateMap[this._deviceId] : null;
            const state = stateEvent
              ? stateEvent.state.status
              : DeviceOfflineStatus.DISCONNECTED;
            if (
              presenceEvent &&
              presenceEvent.presenceIndication.trackerTargets &&
              state !== DeviceOfflineStatus.DISCONNECTED
            ) {
              this.setTargets(presenceEvent.presenceIndication.trackerTargets);
            }
          });
      })
      .catch((error) => console.error("Unable to get config: ", error));
  }

  constructor(
    private deviceMonitoring: DeviceMonitoringService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    @Inject(EXTRA_DEVICE_ID)
    private extras: { deviceId: string; roomName: string; deviceName: string }
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.route.paramMap) {
      this.route.paramMap.subscribe((params) => {
        if (params && params.get("id")) {
          this.device = params.get("id");
        }
      });
      if (this.extras) {
        this.device = this.extras.deviceId ? this.extras.deviceId : "";
        this.deviceName = this.extras.deviceName ? this.extras.deviceName : "";
        this.roomName = this.extras.roomName ? this.extras.roomName : "";
      }
    }
  }

  private getImageForPosture(posture: number) {
    const normalizedPosture =
      posture == null ? 0 : Math.max(0, Math.min(posture, 4));
    return this.imageUrl[normalizedPosture];
  }

  resize(size: { width: number; height: number }) {
    this.images.forEach((image, index) => {
      if (this.imageUrl[index].aspectRatio > 1) {
        const newWidth = size.width * 0.1;
        image.width = newWidth;
        image.height = newWidth / this.imageUrl[index].aspectRatio;
      } else {
        const newHeight = size.height * 0.1;
        image.width = newHeight * this.imageUrl[index].aspectRatio;
        image.height = newHeight;
      }
    });
  }

  isSensorMountingSpecified() {
    return this.config && "sensorMounting" in this.config.walabotConfig;
  }

  formatSensorMounting() {
    return SensorMounting[this.config.walabotConfig.sensorMounting].replace(
      /(?!^)([A-Z]|\d+)/g,
      " $&"
    );
  }

  private setTargets(targets: TrackerTarget[]) {
    const uiTargets: TrackerTarget2D[] = [];
    targets.forEach((target) => {
      uiTargets.push({
        x: target.xPosCm / 100,
        y: target.yPosCm / 100,
        image: this.getImageForPosture(0).url,
      });
    });
    this.targets = uiTargets;
  }

  close() {
    this.closePanel.emit();
  }

  isMetric() {
    return this.unit === MeasurementUnits.Metric;
  }

  getSettings() {
    return {
      deviceSettingsForm: {
        sensorMounting: this.config.walabotConfig.sensorMounting,
      },
    };
  }

  getRoomSizeFromForm() {
    return this.config.walabotConfig as RoomSizeFromForm;
  }

  getSubregionsFromForm(): Array<TrackerSubRegion> {
    return this.config.walabotConfig.trackerSubRegions;
  }

  isWallMount() {
    const sensorMountVal = this.config.walabotConfig.sensorMounting ?? 0;
    return (
      sensorMountVal === SensorMounting.Wall ||
      sensorMountVal === SensorMounting.Tilt45Deg ||
      sensorMountVal == null
    );
  }
}
