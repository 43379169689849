import { Inject, Injectable } from "@angular/core";
import { Suite } from "@walabot-mqtt-dashboard/api";
import { AuthService, HistoryService, SuitesService } from "./ui.module";
import { DateTime } from "luxon";

export type ResidencyStatus = "on_site" | "on_leave";

export type AnalyticsPiece = {
  value: number;
  delta: number;
  previousValue: number;
};

export type AnalyticsShift = {
  shift_name: string;
  shift_id: string;
  data: AnalyticsPiece;
};

export type TopRiskResident = {
  suite_name: string;
  suite_id: string;
  risk_score: number;
  risk_level_id: number;
  residency_status: ResidencyStatus;
};

export type RiskTrendPiece = {
  month: string;
  risk_score: number;
  risk_level_id: number;
};

export interface RiskLevel {
  id: number;
  name: string;
  color: string;
}

export interface AnalyticsFallEvent {
  diff?: string;
  fall_time: string;
  room: string;
  resolution: string;
}

export interface PotentialFall {
  suite_name: string;
  suite_id: string;
  room: string;
  fall_time: string;
}

export interface NewFallers {
  suite_name: string;
  suite_id: string;
  num_of_falls_from_new_fallers?: number;
  last_fall_from_new_fallers_timestamp?: number;
}

export interface FallsByShift {
  num_of_falls: number;
  day_of_week: number;
  shift_id: number;
  shift_name: string;
  fall_time?: string;
}

export type FacilityAnalytics = {
  falls: {
    total: AnalyticsPiece;
    withInjuries: AnalyticsPiece;
    injurySuites: AnalyticsPiece;
    withoutInjuries: AnalyticsPiece;
    unresolved: AnalyticsPiece;
  };
  newFallers: AnalyticsPiece;
  potentialFalls: AnalyticsPiece;
  highRiskResidents: AnalyticsPiece;
  fallRisk: AnalyticsPiece;
  averageResponseTime: AnalyticsPiece;
  outOfApartments: AnalyticsPiece;
  fallsByShift: {
    grouped: Array<AnalyticsShift>;
    byDay: Array<FallsByShift>;
  };
  topRiskResidents: Array<TopRiskResident>;
  fallsHistory: Array<AnalyticsFallEvent>;
  averagePresence: AveragePresenceTime;
  inBedroomDelta: number;
  inBathroomDelta: number;
  outOfApartmentDelta: number;
  riskTrend: Array<RiskTrendPiece>;
  newFallersList: Array<NewFallers>;
  potentialFallList: Array<PotentialFall>;
  startTime: string;
  endTime: string;
  dailyStartTime: string;
  dailyEndTime: string;
  risk_level_id: number;
  dict: {
    risk_level: Array<RiskLevel>;
    benchmark: {
      value: number;
    };
  };
  last_upload: string;
};

export interface AveragePresenceTime {
  in_bedroom: number;
  in_bathroom: number;
  in_livingroom: number;
  in_kitchen: number;
  in_apt_entryway: number;
  out_of_apt: number;
  is_presence_data_valid: boolean;
  is_previous_presence_data_valid: boolean;
}

export interface AveragePresenceData {
  labels: Array<string>;
  colors: Array<string>;
  data: Array<number>;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private suites: Suite[] = [];
  constructor(
    private auth: AuthService,
    private historyService: HistoryService,
    private suitesService: SuitesService,
    @Inject("environment") private environment: Record<string, unknown>
  ) {
    this.suitesService.suiteList.subscribe((suites) => {
      this.suites = suites;
    });
  }

  getFacilityAnalytics() {
    const uid = this.auth.getUser().uid;
    const suiteIds = this.suites.map((s) => s.id);
    const path = `${<string>(
      this.environment.analyticsUrl
    )}/analytics/facility?uid=${uid}`;
    return this.getAnalytics(path, suiteIds);
  }

  getSuiteAnalytics(suiteId: string) {
    const uid = this.auth.getUser().uid;
    const path = `${<string>(
      this.environment.analyticsUrl
    )}/analytics/suite?uid=${uid}&suiteId=${suiteId}`;
    return this.getAnalytics(path, [suiteId]);
  }

  uploadFacilityAnalytics(requestBody: FormData) {
    return fetch(
      `${<string>this.environment.analyticsUrl}/analytics/facility`,
      {
        method: "POST",
        body: requestBody,
      }
    ).then((res) => {
      if (!res.ok) {
        throw res.statusText;
      }
    });
  }

  private getAnalytics(path: string, suiteIds: Array<string>) {
    const fromCreatedAtCurrent = DateTime.fromObject({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
        .minus({ week: 3, day: 6 })
        .toUTC()
        .toISO(),
      toCreatedAtCurrent = DateTime.local().toUTC().toISO(),
      fromCreatedAtPrevious = DateTime.fromObject({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
        .minus({ week: 7, day: 6 })
        .toUTC()
        .toISO(),
      toCreatedAtPrevious = DateTime.fromObject({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
        .minus({ week: 4 })
        .toUTC()
        .toISO();

    return Promise.all([
      fetch(path, {
        headers: {
          Accept: "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          return <Promise<Omit<FacilityAnalytics, "falls" | "dailyUpdatedAt">>>(
            res.json()
          );
        } else {
          throw res.statusText;
        }
      }),
      this.historyService.getAlerts({
        suiteIds,
        fromCreatedAt: fromCreatedAtCurrent,
        toCreatedAt: toCreatedAtCurrent,
        limit: Number.MAX_SAFE_INTEGER,
      }),
      this.historyService.getAlerts({
        suiteIds,
        fromCreatedAt: fromCreatedAtPrevious,
        toCreatedAt: toCreatedAtPrevious,
        limit: Number.MAX_SAFE_INTEGER,
      }),
    ]).then(([analyticsData, fallsHistoryCurrent, fallsHistoryPrevious]) => {
      const fallsCurrent = {
          total: {
            value: 0,
            delta: 0,
            previousValue: 0,
          },
          injurySuites: {
            value: 0,
            delta: 0,
            previousValue: 0,
          },
          withInjuries: {
            value: 0,
            delta: 0,
            previousValue: 0,
          },
          withoutInjuries: {
            value: 0,
            delta: 0,
            previousValue: 0,
          },
          unresolved: {
            value: 0,
            delta: 0,
            previousValue: 0,
          },
        },
        fallsPrevious = structuredClone(fallsCurrent);

      const fallsData = [
        {
          history: fallsHistoryCurrent,
          data: fallsCurrent,
          injurySuitesMap: new Map<string, string>(),
        },
        {
          history: fallsHistoryPrevious,
          data: fallsPrevious,
          injurySuitesMap: new Map<string, string>(),
        },
      ];
      fallsData.forEach((entity) => {
        entity.history.forEach((event) => {
          switch (event.updatedResolutionType ?? event.resolutionType) {
            case "Injury":
              entity.data.total.value++;
              entity.data.withInjuries.value++;
              if (event.suiteId) {
                entity.injurySuitesMap.set(event.suiteId, event.suiteId);
              }
              break;
            case "Confirmed":
              entity.data.total.value++;
              entity.data.withoutInjuries.value++;
              if (event.suiteId) {
                entity.injurySuitesMap.set(event.suiteId, event.suiteId);
              }
              break;
            case "Test":
            case "NoFall":
              break;
            default:
              entity.data.unresolved.value++;
              break;
          }
        });
      });
      fallsCurrent.injurySuites.value = fallsData[0].injurySuitesMap.size;
      fallsPrevious.injurySuites.value = fallsData[1].injurySuitesMap.size;

      (<Array<keyof typeof fallsCurrent>>[
        "total",
        "injurySuites",
        "withInjuries",
        "withoutInjuries",
        "unresolved",
      ]).forEach((category) => {
        const preValue = fallsPrevious[category].value;
        fallsCurrent[category].previousValue = preValue;
        if (preValue) {
          const diffValue = fallsCurrent[category].value - preValue;
          fallsCurrent[category].delta = Math.round(
            (diffValue * 100) / preValue
          );
        }
      });

      return Object.assign(analyticsData, {
        falls: fallsCurrent,
        dailyStartTime: fromCreatedAtCurrent,
        dailyEndTime: toCreatedAtCurrent,
      });
    });
  }
}
