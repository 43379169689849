/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertResponse } from '../models/AlertResponse';
import type { NotifyNCUpdateAlertResolutionRequest } from '../models/NotifyNCUpdateAlertResolutionRequest';
import type { ResolutionType } from '../models/ResolutionType';
import type { UpdateAlertResolutionRequest } from '../models/UpdateAlertResolutionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlertAPI {

    /**
     * Update Alert Resolution
     * @param alertId
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static updateAlertResolution(
        alertId: string,
        requestBody?: UpdateAlertResolutionRequest,
    ): CancelablePromise<'OK'> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/alert/{alertId}/resolution',
            path: {
                'alertId': alertId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update Alert Resolution from NotifyNC
     * @param alertId
     * @param apiKey
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static updateNotifyNcAlertResolution(
        alertId: string,
        apiKey?: string,
        requestBody?: NotifyNCUpdateAlertResolutionRequest,
    ): CancelablePromise<'OK'> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/partners/notifync/alerts/{alertId}/resolution',
            path: {
                'alertId': alertId,
            },
            query: {
                'apiKey': apiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Alerts
     * @param alertIds The unique identifier of the alert
     * @param deviceIds The unique identifier of the device
     * @param suiteIds The unique identifier of the suite
     * @param unassignedDeviceIds The unique identifier of the unassigned device
     * @param fromCreatedAt
     * @param toCreatedAt
     * @param sources
     * @param fromSourceTime
     * @param toSourceTime
     * @param statuses The source of the alert, ex- &statuses=Active&statuses=Resolved
     * @param resolutionReportedBys The resolution reported by
     * @param takenBys The alert taken by
     * @param resolutionTypes The resolution types
     * @param resolutionEditedBys The resolution edited by
     * @param updatedResolutionTypes The resolution edited by
     * @param limit
     * @param offset
     * @returns AlertResponse OK
     * @throws ApiError
     */
    public static getAlerts(
        alertIds?: (string | Array<string>),
        deviceIds?: (string | Array<string>),
        suiteIds?: (string | Array<string>),
        unassignedDeviceIds?: (string | Array<string>),
        fromCreatedAt?: string,
        toCreatedAt?: string,
        sources?: (string | Array<string>),
        fromSourceTime?: string,
        toSourceTime?: string,
        statuses?: (string | Array<string>),
        resolutionReportedBys?: (string | Array<string>),
        takenBys?: (string | Array<string>),
        resolutionTypes?: (string | Array<ResolutionType>),
        resolutionEditedBys?: (string | Array<string>),
        updatedResolutionTypes?: (string | Array<ResolutionType>),
        limit?: number,
        offset?: number,
    ): CancelablePromise<Array<AlertResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert',
            query: {
                'alertIds': alertIds,
                'deviceIds': deviceIds,
                'suiteIds': suiteIds,
                'unassignedDeviceIds': unassignedDeviceIds,
                'fromCreatedAt': fromCreatedAt,
                'toCreatedAt': toCreatedAt,
                'sources': sources,
                'fromSourceTime': fromSourceTime,
                'toSourceTime': toSourceTime,
                'statuses': statuses,
                'resolutionReportedBys': resolutionReportedBys,
                'takenBys': takenBys,
                'resolutionTypes': resolutionTypes,
                'resolutionEditedBys': resolutionEditedBys,
                'updatedResolutionTypes': updatedResolutionTypes,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Take Alert for Resolution
     * @param alertId
     * @returns string OK
     * @throws ApiError
     */
    public static takeAlert(
        alertId: string,
    ): CancelablePromise<'OK'> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/alert/{alertId}/take',
            path: {
                'alertId': alertId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }

}
