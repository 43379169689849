/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enable/Disable SNR points policy for telemetry data
 */
export enum SnrPointsPolicy {
    On = 'On',
    Off = 'Off',
    OnFall = 'OnFall',
}
